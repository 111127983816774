// Fontawesome Icons.
import {
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faCalendarCheck,
    faCheck,
    faCircleCheck,
    faCircleExclamation,
    faCircleQuestion,
    faCircleInfo,
    faLanguage,
    faPencil,
    faXmark,
    faRotateRight,
} from '@fortawesome/free-solid-svg-icons';
import { Icon, library } from '@fortawesome/fontawesome-svg-core';

const icons = [
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faCheck,
    faCalendarCheck,
    faCircleCheck,
    faCircleExclamation,
    faCircleQuestion,
    faCircleInfo,
    faLanguage,
    faPencil,
    faXmark,
    faRotateRight,
] as Icon[];

/**
 * Create a library to use icons in all React components.
 * Import your icons and add it to the library to make them available.
 */
library.add(...icons);

import './HelpSupportModal.css';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePortalConfiguration } from '../../configuration/PortalConfigurationContext';
import { PortalConfiguration } from '../../configuration/types';
import Modal from './Modal';

interface HelpSupportModalProps {
    openModal: boolean;
    handleCloseModal: () => void;
}

export default function HelpSupportModal({ openModal, handleCloseModal }: Readonly<HelpSupportModalProps>) {
    const { t } = useTranslation('common');
    const { displayConfiguration } = usePortalConfiguration().configuration as PortalConfiguration;
    const { helpSection } = displayConfiguration;

    return (
        <Modal
            title={t('affix.title')}
            titleIcon={<FontAwesomeIcon icon="question-circle" />}
            className="help-support-modal"
            footer={<Button className="help-support-modal-button" onClick={() => handleCloseModal()}>Ok</Button>}
            onCancel={() => handleCloseModal()}
            open={openModal}
        >
            <span>{t(helpSection?.helpMessage ?? '')}</span>

            {helpSection?.helpSupportPhone ? (
                <div>
                    <div>{t('affix.phone')}</div>
                    <a className="help-support-modal-link" href={`tel:${helpSection?.helpSupportPhone}`}>{helpSection?.helpSupportPhone.replace(/(.{2})/g, '$& ')}</a>
                </div>
            ) : null}

            {helpSection?.helpSupportEmail ? (
                <div>
                    <div>{t('affix.email')}</div>
                    <a className="help-support-modal-link" href={`mailto:${helpSection?.helpSupportEmail}`}>
                        {helpSection?.helpSupportEmail}
                    </a>
                </div>
            ) : null}
        </Modal>
    );
}

import React, { useCallback, useEffect, useState } from 'react';
import './MainCustomerView.css';
import {
    Affix, Alert, Button, Drawer, notification, Spin, Tooltip,
} from 'antd';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Progress from '../../Common/Progress/Progress';
import SlotSelection from '../../Common/SlotSelection/SlotSelection';
import CustomerForm from '../../Common/CustomerForm/CustomerForm';
import Summary from '../../Common/Summary/Summary';
import Footer from '../../Common/Footer/Footer';
import BookingConfirmation from '../../Common/Confirmation/BookingConfirmation';
import AdminHeader from '../../Common/adminHeader/adminHeader';
import TechTracking from '../../Common/techTracking/TechTracking';
import WorkOrderSummaryPage from '../WorkOrderSummaryPage';
import { usePortalConfiguration } from '../../configuration/PortalConfigurationContext';
import { PortalConfiguration } from '../../configuration/types';
import HelpSupportModal from '../../Common/Modal/HelpSupportModal';
import ErrorView from '../feedback/ErrorView';

enum WorkOrderState {
    NO_SCHEDULING = 'NO_SCHEDULING',
    APPOINTMENT_CONFIRMED = 'APPOINTMENT_CONFIRMED',
    IN_PROGRESS = 'IN_PROGRESS',
    ETA = 'ETA',
    COMPLETED = 'COMPLETED',
    UNDEFINED = 'UNDEFINED',
}

interface WorkOrderAlert {
    workOrderId: number,
    alertType: string,
    alertValue: string,
}

enum AchievementAlertValue {
    RISK = 'RISK',
    LATE = 'LATE',
    FAILED = 'FAILED',
}

export default function MainCustomerView(this: any) {
    const DETAILS_FORM = 0;
    const SLOT_SELECTION = 1;
    const SUMMARY_FORM = 2;

    const [t] = useTranslation('common');
    const [selectedSlot, setSelectedSlot] = useState<any>();
    const [travelTime, setTravelTime] = useState<number>();
    const [workOrderState, setWorkOrderState] = useState<WorkOrderState>();
    const [alert, setAlert] = useState<WorkOrderAlert>();
    const [step = DETAILS_FORM, setStep] = useState<number>();
    const [buttonState = t('main.buttons.next'), setButtonState] = useState<string>();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [additionalInfo = '', setAdditionalInfo] = useState<string>();
    const [workOrder, setWorkOrder] = useState<any>();
    const [eta, setEta] = useState<any>();
    const [newPhoneNumber, setNewPhoneNumber] = useState<any>();
    const [newEmailAddress, setNewEmailAddress] = useState<any>();
    const [formValidation, setFormValidation] = useState<boolean>();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [loading, setLoading] = useState(true);
    const { uuid } = useParams();
    // The user clicked on the "Reschedule the appointment".
    const [isRescheduling, setIsRescheduling] = useState(false);
    const { displayConfiguration } = usePortalConfiguration().configuration as PortalConfiguration;
    const { helpSection, unauthorizedSchedulePageConfiguration } = displayConfiguration;

    function getAlert(publicId: string) {
        return fetch(`/service/${publicId}/alert`)
            .then((response) => (response.status === 200 ? response.json() : null))
            .then((alert) => {
                if (alert) {
                    setAlert(alert);
                }
            })
            .catch((error) => console.error(error));
    }

    function getETA(publicId: string) {
        return fetch(`/service/${publicId}/eta`)
            .then((response) => response.json())
            .then((eta) => {
                if (eta) {
                    setEta(eta);
                    setTravelTime(eta.travelTime);
                }
            })
            .catch((error) => console.error(error));
    }

    async function getWorkOrderState(publicId) {
        return fetch(`/service/${publicId}/state`)
            .then((response) => response.json())
            .then((workOrderState) => {
                if (workOrderState) {
                    setWorkOrderState(workOrderState);
                }
            })
            .catch((error) => console.error(error));
    }

    async function getWorkOrder(publicId) {
        const result = await fetch(`/service/${publicId}`)
            .then((response) => response.json())
            .catch((error) => console.error(error));

        if (result !== undefined) {
            await Promise.all([getAlert(publicId), getETA(publicId)]);
            setWorkOrder(result);
            setNewEmailAddress(result.customer?.email);
            setNewPhoneNumber(result.customer?.phone);
            setAdditionalInfo(result.siteDescription);
        }
        setLoading(false);
    }

    const onClose = () => {
        setOpenDrawer(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            await getWorkOrderState(uuid);
            await getWorkOrder(uuid);
        };
        fetchData().then();
    }, [uuid]);

    async function handleNextStep() {
        if (step === DETAILS_FORM) {
            if (formValidation) {
                setStep(step + 1);
                setSelectedSlot(undefined);
            }
        }
        if (step === SLOT_SELECTION) {
            setButtonState(t('main.buttons.confirm'));
            setStep(step + 1);
        }
        if (step === SUMMARY_FORM) {
            const response = await handleConfirm();
            if (!response.ok) {
                notification.error({
                    message: workOrder.status === 1 ? t('booking.error.scheduled') : t('booking.error.rescheduled'),
                    className: 'notification notification-error',
                    icon: <FontAwesomeIcon icon="circle-exclamation" className="iconBig icon-secondary" />,
                    closeIcon: <FontAwesomeIcon icon="xmark" className="iconBig icon-grey" />,
                    placement: 'top',
                    duration: 20,
                });
            } else {
                getWorkOrderState(uuid);
                getWorkOrder(uuid); // Pourquoi ?
                setButtonState(t('main.buttons.confirm'));
            }
        }
    }

    function updateWorkOrder(nworkOrder) {
        setWorkOrder(nworkOrder);
    }

    function resetSlot() {
        setSelectedSlot(undefined);
    }

    function selectSlot(slot) {
        setSelectedSlot(slot);
    }

    function newAdditionalInfo(info) {
        const stepWorkOrder = workOrder;
        stepWorkOrder.siteDescription = info.target.value;
        setWorkOrder(stepWorkOrder);
        setAdditionalInfo(info.target.value);
    }

    function handleOpenModal() {
        setOpenModal(true);
    }

    const handleCloseModal = useCallback(() => {
        setOpenModal(false);
    }, []);

    function validateForm(validation) {
        setFormValidation(validation);
    }

    function changePhoneNumber(number) {
        setNewPhoneNumber(number);
    }

    function changeEmailAddress(address) {
        setNewEmailAddress(address);
    }

    let customerForm;
    let modal;
    let affix;
    if (workOrder !== undefined) {
        customerForm = workOrder.error === undefined ? [
            <CustomerForm
                key="customerForm"
                workOrder={workOrder}
                updateWorkOrder={updateWorkOrder}
                validateForm={validateForm}
                setPhoneNumber={changePhoneNumber}
                setEmailAddress={changeEmailAddress}
                additionalInfo={additionalInfo}
                newAdditionalInfo={newAdditionalInfo}
                newPhoneNumber={newPhoneNumber}
                newEmailAddress={newEmailAddress}
            />,
        ] : [];
        modal = workOrder.error === undefined ? <HelpSupportModal openModal={openModal} handleCloseModal={handleCloseModal} /> : [];

        affix = helpSection?.showHelp ? [
            <Affix key="affix" className="help-affix">
                <Button shape="circle" className="help-button" onClick={() => handleOpenModal()}>?</Button>
                {modal}
            </Affix>,
        ] : undefined;
    }

    function rescheduleAppointment() {
        setWorkOrderState(undefined); // Clean the state due to legacy behavior (not migrated yet)
        setStep(1);
        setIsRescheduling(true);
        setSelectedSlot(undefined);
        setButtonState(t('main.buttons.next'));
    }

    function handlePreviousStep() {
        if (step === SUMMARY_FORM) {
            setButtonState(t('main.buttons.next'));
            setSelectedSlot(undefined);
            setStep(step - 1);
        } else if (step > DETAILS_FORM) {
            setButtonState(t('main.buttons.next'));
            setStep(step - 1);
        }
    }

    async function handleConfirm() {
        const body = {
            workOrder: undefined,
            slot: {
                technicianId: undefined,
                startDate: undefined,
                endDate: undefined,
            },
            coordinates: {
                phone: undefined,
                email: undefined,
            },
        };
        body.workOrder = workOrder;
        body.slot.technicianId = selectedSlot.technicianId;
        body.slot.startDate = selectedSlot.start;
        body.slot.endDate = selectedSlot.end;
        body.coordinates.phone = newPhoneNumber;
        body.coordinates.email = newEmailAddress;

        return fetch(
            `/service/${uuid}/schedule`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body),
            },
        ).then((response) => response);
    }

    function formatDate(date: Date): string {
        let formattedDate = date.toISOString();
        formattedDate = `${formattedDate.split('.')[0]}Z`;
        formattedDate = formattedDate.replace(/-/g, '');
        formattedDate = formattedDate.replace(/:/g, '');
        return formattedDate;
    }

    function formatDateForOutlook(date: Date): string {
        let formattedDate = date.toISOString();
        formattedDate = `${formattedDate.split('.')[0]}Z`;
        formattedDate = formattedDate.replace(/:/g, '%3A');
        return formattedDate;
    }

    function generateIcalContent(sourceWorkOrder: any, startDate: string, endDate: string, eventTitle: string): string {
        return 'BEGIN:VCALENDAR\n'
            + 'VERSION:2.0\n'
            + 'BEGIN:VEVENT\n'
            + `DTSTAMP:${formatDate(new Date())}\n`
            + `DTSTART:${startDate}\n`
            + `DTEND:${endDate}\n`
            + `SUMMARY:${eventTitle}\n`
            + `LOCATION:${sourceWorkOrder.address} ${sourceWorkOrder.zipcode}, ${sourceWorkOrder.city}\n`
            + `DESCRIPTION:${sourceWorkOrder.description}\n`
            + 'END:VEVENT\n'
            + 'END:VCALENDAR';
    }

    function generateCalendarOptions() {
        const { userAgent } = navigator;
        const eventTitle = 'Intervention';
        const startDate = formatDate(new Date(workOrder.status === 1 ? selectedSlot.start : workOrder.scheduledStartDate));
        const endDate = formatDate(new Date(workOrder.status === 1 ? selectedSlot.end : workOrder.scheduledEndDate));
        const Ical = generateIcalContent(workOrder, startDate, endDate, eventTitle);
        const link = document.createElement('a');

        if (/iPad|iPhone|iPod/.test(userAgent)) {
            const blob = new Blob([Ical], { type: 'text/calendar' });
            link.href = window.URL.createObjectURL(blob);
            link.download = 'event.ics';
            link.click();
        } else if (/Android/.test(userAgent)) {
            link.href = `http://www.google.com/calendar/render?action=TEMPLATE&text=${eventTitle}&dates=${startDate}/${endDate}&details=${workOrder.description.replace(/ /g, '%20')}&location=${workOrder.address.replace(/ /g, '%20')}+${workOrder.zipcode},+${workOrder.city}&sf=true&output=xml`;
            link.click();
        } else {
            setOpenDrawer(true);
        }
    }

    function generateCalendar(calendar: string) {
        const eventTitle = 'Intervention';
        const startDate = new Date(workOrder.status === 1 ? selectedSlot.start : workOrder.scheduledStartDate);
        const endDate = new Date(workOrder.status === 1 ? selectedSlot.end : new Date(workOrder.scheduledEndDate).getTime() + workOrder.authorizedDelay * 60000);
        const formatedStartDate = formatDate(startDate);
        const formatedEndDate = formatDate(endDate);
        const Ical = generateIcalContent(workOrder, formatedStartDate, formatedEndDate, eventTitle);
        const link = document.createElement('a');
        const blob = new Blob([Ical], { type: 'text/calendar' });

        switch (calendar) {
            case 'google':
                window.open(`https://www.google.com/calendar/render?action=TEMPLATE&text=${eventTitle}&dates=${formatedStartDate}/${formatedEndDate}&details=${eventTitle}&location=${workOrder.address.replace(/ /g, '%20')}+${workOrder.zipcode},+${workOrder.city}&sf=true&output=xml`, '_blank');
                break;
            case 'yahoo':
                window.open(`https://calendar.yahoo.com/?desc=${eventTitle}&et=${formatedEndDate}&in_loc=${workOrder.address.replace(/ /g, '%20')}+${workOrder.zipcode},+${workOrder.city}&st=${formatedStartDate}&title=${eventTitle}&v=60`);
                break;
            case 'outlook':
                window.open(`https://outlook.live.com/calendar/0/action/compose?body=${eventTitle}&startdt=${formatDateForOutlook(startDate)}&enddt=${formatDateForOutlook(endDate)}&location=${workOrder.address.replace(/ /g, '%20')}+${workOrder.zipcode},+${workOrder.city}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&subject=${eventTitle}`);
                break;
            case 'ical':
                link.href = window.URL.createObjectURL(blob);
                link.download = 'event.ics';
                link.click();
                break;
            default:
                break;
        }
    }

    if (workOrder !== undefined && workOrder.error === undefined) {
        if (workOrderState === WorkOrderState.NO_SCHEDULING) {
            return unauthorizedSchedulePageConfiguration && (
                <div className="wrapper">
                    <AdminHeader />
                    <div className="generalWrapper">

                        <div className="params">
                            <h1>{t(unauthorizedSchedulePageConfiguration.title)}</h1>
                        </div>

                        <div className="selectWrapper">
                            <div className="centerPanel">
                                <div className="dataDisplay">
                                    <p>
                                        {t(unauthorizedSchedulePageConfiguration.description)}
                                        <br />
                                        {unauthorizedSchedulePageConfiguration.phoneNumber}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                    {affix}
                </div>
            );
        }
        if (workOrderState === WorkOrderState.IN_PROGRESS) {
            return (
                <WorkOrderSummaryPage
                    title="main.title.inProgress"
                    workOrder={workOrder}
                    selectedSlot={selectedSlot}
                    additonalInfo={additionalInfo}
                    newPhoneNumber={newPhoneNumber}
                    newEmailAddress={newEmailAddress}
                    rescheduleAppointment={rescheduleAppointment}
                    saveCalendar={() => generateCalendarOptions()}
                    affix={affix}
                />
            );
        }
        if (workOrderState === WorkOrderState.APPOINTMENT_CONFIRMED) {
            const yesterday = new Date(workOrder.scheduledStartDate);
            yesterday.setDate(yesterday.getDate() - 1);
            const newETA = new Date();
            let ETAMessage = t('lateAppointment');
            if (travelTime && travelTime > 0) {
                newETA.setMilliseconds(newETA.getMilliseconds() + travelTime);
                ETAMessage += t('lateAppointmentETA', {
                    ETA: newETA.toLocaleString(
                        t('date.locale'),
                        {
                            hour: '2-digit',
                            minute: '2-digit',
                        },
                    ),
                });
            }
            return (
                <div className="wrapper">
                    <AdminHeader />
                    <div className="generalWrapper booking-confirmation-wrapper">
                        <div className="params">
                            <h1>{t('main.title.confirmed')}</h1>
                        </div>

                        {alert?.alertValue === AchievementAlertValue.LATE
                            ? (
                                <Alert
                                    className="isLateAlert"
                                    message={ETAMessage}
                                    description={t('lateAppointmentDescription')}
                                    type="error"
                                    showIcon
                                />
                            )
                            : alert?.alertValue === AchievementAlertValue.FAILED
                                ? (
                                    <Alert
                                        className="isLateAlert"
                                        message={ETAMessage}
                                        description={t('lateAppointmentDescription')}
                                        type="error"
                                        showIcon
                                    />
                                )
                                : <></>}
                        {/* <div className="rescheduleButtons">
                            {((alert && alert.alertValue !== AchievementAlertValue.RISK) ? (
                                <><Button className="editButton" onClick={() => rescheduleAppointment()}>{t("main.buttons.edit")} &nbsp;
                                    <FontAwesomeIcon
                                        icon="pencil"/></Button></>

                            ) : <></>)}
                        </div> */}

                        <div className="selectWrapper">
                            <div className="centerPanel">
                                <div className="dataDisplay">
                                    <BookingConfirmation
                                        workOrder={workOrder}
                                        selectedSlot={selectedSlot}
                                        additonalInfo={additionalInfo}
                                        newPhoneNumber={newPhoneNumber}
                                        newEmailAddress={newEmailAddress}
                                        rescheduleAppointment={rescheduleAppointment}
                                        saveCalendar={() => generateCalendarOptions()}
                                        withCalendarButton
                                        withRescheduleButton
                                        withAppointmentRange
                                    />
                                </div>
                            </div>
                        </div>

                        <Drawer title={t('main.buttons.save')} placement="right" onClose={onClose} open={openDrawer}>
                            <div className="agendaPanelButtonContainer">
                                <Button
                                    className="agendaPanelButton"
                                    onClick={() => generateCalendar('google')}
                                >
                                    Google
                                    calendar
                                </Button>
                            </div>
                            <div className="agendaPanelButtonContainer">
                                <Button
                                    className="agendaPanelButton"
                                    onClick={() => generateCalendar('yahoo')}
                                >
                                    Yahoo
                                    calendar
                                </Button>
                            </div>
                            <div className="agendaPanelButtonContainer">
                                <Button
                                    className="agendaPanelButton"
                                    onClick={() => generateCalendar('outlook')}
                                >
                                    Outlook
                                    calendar
                                </Button>
                            </div>
                            <div className="agendaPanelButtonContainer">
                                <Button
                                    className="agendaPanelButton"
                                    onClick={() => generateCalendar('ical')}
                                >
                                    iCal
                                </Button>
                            </div>
                        </Drawer>
                    </div>
                    <Footer />
                    {affix}
                </div>
            );
        }
        if (workOrderState === WorkOrderState.ETA && eta) {
            return (
                <div className="wrapper">
                    <AdminHeader />
                    <div className="generalWrapper">
                        <div className="params params-tracking"><h1>{t('main.title.tracking')}</h1></div>
                        <TechTracking
                            latDest={eta.destinationGeolocation.latitude}
                            lngDest={eta.destinationGeolocation.longitude}
                            latTech={eta.technicianGeolocation.latitude}
                            lngTech={eta.technicianGeolocation.longitude}
                            apiKey={eta.apiKey}
                            workOrder={workOrder}
                            selectedSlot={selectedSlot}
                            additonalInfo={additionalInfo}
                            newPhoneNumber={newPhoneNumber}
                            newEmailAddress={newEmailAddress}
                            rescheduleAppointment={rescheduleAppointment}
                            saveCalendar={() => generateCalendarOptions()}
                            isReschedulableAppointment={eta.reschedulableAppointment}
                        />
                    </div>
                    <Footer />
                    {affix}
                </div>
            );
        }

        if (workOrderState === WorkOrderState.COMPLETED) {
            return (
                <WorkOrderSummaryPage
                    title="main.title.completed"
                    workOrder={workOrder}
                    selectedSlot={selectedSlot}
                    additonalInfo={additionalInfo}
                    newPhoneNumber={newPhoneNumber}
                    newEmailAddress={newEmailAddress}
                    rescheduleAppointment={rescheduleAppointment}
                    saveCalendar={() => generateCalendarOptions()}
                />
            );
        }

        if (step === DETAILS_FORM) {
            return (
                <div className="wrapper">
                    <AdminHeader />
                    <div className="generalWrapper">
                        <div className="params">
                            <h1>{t('main.title.booking')}</h1>
                        </div>
                        <div className="selectWrapper">
                            <Progress step={step} />
                            <div className="centerPanel">

                                <div className="dataDisplay">
                                    {customerForm}
                                </div>
                                <div className="navButtons">
                                    <Tooltip title={formValidation ? undefined : t('form.tooltip')}>
                                        <Button
                                            className="nextStep"
                                            onClick={() => handleNextStep()}
                                            disabled={!formValidation}
                                        >
                                            {buttonState}
                                        </Button>
                                    </Tooltip>
                                </div>
                            </div>

                        </div>
                    </div>
                    <Footer />
                    {affix}
                </div>
            );
        }
        if (step === SLOT_SELECTION) {
            return (
                <div className="wrapper">
                    <AdminHeader />
                    <div className="generalWrapper">
                        <div className="params">
                            <h1>{t('main.title.booking')}</h1>
                        </div>
                        <div className="selectWrapper">
                            <Progress step={step} hideFirstStep={isRescheduling} />
                            <div className="centerPanel">

                                <div className="dataDisplay">
                                    <SlotSelection
                                        selectSlot={selectSlot}
                                        resetSlot={resetSlot}
                                        address={workOrder.address}
                                        zipCode={workOrder.zipcode}
                                        city={workOrder.city}
                                        uuid={uuid}
                                        authorizedDelay={workOrder.authorizedDelay}
                                    />

                                </div>

                                <div className="navButtons">
                                    {isRescheduling || (
                                        <Button
                                            className="previousStep"
                                            type="link"
                                            icon={<FontAwesomeIcon icon="arrow-left" />}
                                            onClick={() => handlePreviousStep()}
                                        >
                                            {t('main.buttons.previous')}
                                        </Button>
                                    )}
                                    <Tooltip title={selectedSlot === undefined ? t('slots.tooltip') : undefined}>
                                        <Button
                                            className="nextStep"
                                            disabled={selectedSlot === undefined}
                                            onClick={() => handleNextStep()}
                                        >
                                            {buttonState}
                                        </Button>
                                    </Tooltip>

                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                    {affix}
                </div>
            );
        }
        if (step === SUMMARY_FORM) {
            return (
                <div className="wrapper">
                    <AdminHeader />
                    <div className="generalWrapper">

                        <div className="params">
                            <h1>{t('main.title.booking')}</h1>

                        </div>

                        <div className="selectWrapper">
                            <Progress step={step} hideFirstStep={isRescheduling} />
                            <div className="centerPanel">

                                <div className="dataDisplay">

                                    <Summary
                                        workOrder={workOrder}
                                        selectedSlot={selectedSlot}
                                        additionalInfo={additionalInfo}
                                        newPhoneNumber={newPhoneNumber}
                                        newEmailAddress={newEmailAddress}
                                    />

                                </div>
                                <div className="navButtons">
                                    <Button
                                        className="previousStep"
                                        type="link"
                                        icon={<FontAwesomeIcon icon="arrow-left" />}
                                        onClick={() => handlePreviousStep()}
                                    >
                                        {t('main.buttons.previous')}
                                    </Button>
                                    <Button className="nextStep" onClick={() => handleNextStep()}>
                                        {buttonState}
                                    </Button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <Footer />
                    {affix}
                </div>
            );
        }
        return <ErrorView />;
    }
    if (workOrder !== undefined) {
        if (workOrder.error !== undefined) {
            return <ErrorView />;
        }
        return <ErrorView />;
    }
    if (loading) {
        return (
            <div className="spin">
                <Spin size="large" />
            </div>
        );
    }
    return <ErrorView />;
}

import { Steps } from 'antd';
import React from 'react';
import './Progress.css';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default function Progress(props) {
    const { t } = useTranslation('common');
    const { step, hideFirstStep } = props;
    let internalStep = step;
    let items = [
        {
            title: t('progress.information'),
            icon: step >= 1 ? <FontAwesomeIcon icon="check" style={{ width: 16, height: 16, color: 'var(--feedback-success-onSuccess-color)' }} /> : undefined,
        },
        {
            title: t('progress.booking'),
            icon: step >= 2 ? <FontAwesomeIcon icon="check" style={{ width: 16, height: 16, color: 'var(--feedback-success-onSuccess-color)' }} /> : undefined,
        },
        {
            title: t('progress.confirmation'),
            icon: step >= 3 ? <FontAwesomeIcon icon="check" style={{ width: 16, height: 16, color: 'var(--feedback-success-onSuccess-color)' }} /> : undefined,
        },
    ];

    if (hideFirstStep) {
        items = items.slice(1);
        internalStep -= 1;
    }
    return (
        <div className={`progressWrapper ${hideFirstStep ? 'progressWrapperNoFirst' : ''}`}>
            <Steps
                responsive={false}
                current={internalStep}
                labelPlacement="vertical"
                items={items}
            />
        </div>
    )   ;
}

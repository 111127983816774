import { Outlet, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { loadConfiguration, usePortalConfiguration } from './PortalConfigurationContext';
import { DisplayConfiguration, UrlConfiguration } from './types';

export default function PortalConfigurationLoader() {
    const { configuration, dispatch } = usePortalConfiguration();
    const { uuid } = useParams();
    const { i18n, t } = useTranslation('common');

    useEffect(() => {
        if (uuid) {
            loadConfiguration(dispatch, uuid).then();
        }
    }, [dispatch, uuid]);

    function redirectToCustomUrl(urlConfiguration: UrlConfiguration) {
        if (urlConfiguration.url && urlConfiguration.url !== `${window.location.protocol}//${window.location.host}`) {
            window.location.replace(`${urlConfiguration.url}${window.location.pathname}`);
        }
    }

    function applyDisplayConfiguration(displayConfiguration: DisplayConfiguration) {
        const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
        if (link) {
            link.href = displayConfiguration.favicon;
        }
        document.documentElement.style.setProperty('--desktop-logo-url', `url(${displayConfiguration.masterLogo})`);
        document.documentElement.style.setProperty('--mobile-logo-url', `url(${displayConfiguration.smallLogo})`);

        // Load default skin
        const defaultSkinLink = document.createElement('link');
        defaultSkinLink.rel = 'stylesheet';
        defaultSkinLink.type = 'text/css';
        defaultSkinLink.href = '/style/default-theme.css';
        document.head.appendChild(defaultSkinLink);

        // Load the skin
        if (displayConfiguration.skin !== 'default') {
            const skinLink = document.createElement('link');
            skinLink.rel = 'stylesheet';
            skinLink.type = 'text/css';
            skinLink.href = `/style/skin/${displayConfiguration.skin}/theme.css`;
            document.head.appendChild(skinLink);
        }
    }

    useEffect(() => {
        const selectedLanguage = localStorage.getItem('selectedLanguage');
        const changeLanguage = (lang: string) => i18n.changeLanguage(lang);

        if (configuration) {
            const lang = selectedLanguage ?? configuration.displayConfiguration.language;
            changeLanguage(lang).then(() => {
                applyDisplayConfiguration(configuration.displayConfiguration);
            });
            redirectToCustomUrl(configuration.urlConfiguration);
        } else if (selectedLanguage) {
            changeLanguage(selectedLanguage);
        }
    }, [configuration, i18n]);

    useEffect(() => {
        if (configuration) {
            const { titleConfiguration } = configuration.displayConfiguration;
            let title = t(`${configuration.displayConfiguration.titleConfiguration.title}`);
            if (titleConfiguration.companyName) {
                title = `${titleConfiguration.companyName} - ${title}`;
            }
            document.title = title;
        }
    }, [configuration, t]);

    if (configuration) {
        return <Outlet />;
    }
    return (
        <div className="spin">
            <Spin size="large" />
        </div>
    );
}

import AdminHeader from "../Common/adminHeader/adminHeader";
import BookingConfirmation from "../Common/Confirmation/BookingConfirmation";
import Footer from "../Common/Footer/Footer";
import React from "react";
import {useTranslation} from "react-i18next";

function WorkOrderSummaryPage(props) {
    const { t } = useTranslation('common');
    return (
        <div className="wrapper">
            <AdminHeader/>
            <div className="generalWrapper">
                <div className="params">
                    <h1>
                        {t(props.title)}
                        {' '}
                    </h1>
                </div>
                <div className="selectWrapper">
                    <div className="centerPanel">
                        <div className="dataDisplay">
                            <BookingConfirmation
                                workOrder={props.workOrder}
                                selectedSlot={props.selectedSlot}
                                additonalInfo={props.additionalInfo}
                                newPhoneNumber={props.newPhoneNumber}
                                newEmailAddress={props.newEmailAddress}
                                rescheduleAppointment={props.rescheduleAppointment}
                                saveCalendar={props.saveCalendar}
                            />
                        </div>
                        <div className="close-window">{t('done.close')}</div>
                    </div>
                </div>

                <Footer/>
                {props.affix}
            </div>
        </div>
    );
}

export default WorkOrderSummaryPage;

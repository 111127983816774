import { ReactNode } from 'react';
import { Modal as AntdModal, ModalProps as AntdModalProps } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Modal.css';

export enum ModalSize {
    DEFAULT = 'default',
    LARGE = 'large',
}

type ModalProps = {
    size?: ModalSize,
    title: string,
    titleIcon?: ReactNode,
    footer?: ReactNode,
} & Omit<AntdModalProps, 'footer'>;

const baseClassName = 'modal';

/**
 * Base modal component.
 * @param children the content placed in the body of the modal
 * @param className custom class name for the modal
 * @param closable when true, a close button is rendered in the top right corner of the modal
 * @param destroyOnClose when true, it unmounts child components on onClose, false otherwise
 * @param footer the content placed in the footer of the modal
 * @param onCancel function called when the user closes the modal (by clicking on the mask or by pressing the Close or Cancel button)
 * @param open when true, it makes the modal dialog visible, false otherwise
 * @param size the size of the modal
 * @param title the title for the modal
 * @param titlePrefixIcon the prefix icon for the title
 */
export default function Modal({
    children,
    className = '',
    closable = true,
    destroyOnClose = false,
    footer,
    onCancel,
    open = true,
    size = ModalSize.DEFAULT,
    title,
    titleIcon,
}: ModalProps) {
    return (
        <AntdModal
            centered
            className={`${baseClassName} ${className}`}
            closable={closable}
            closeIcon={<FontAwesomeIcon icon="xmark" className="icon-grey" />}
            destroyOnClose={destroyOnClose}
            footer={<div className="modal-footer">{footer}</div>}
            onCancel={onCancel}
            open={open}
            title={(
                <div className={`${baseClassName}-title`}>
                    {titleIcon && <div className={`${baseClassName}-title-icon`}>{titleIcon}</div>}
                    <div className={`${baseClassName}-title-value`}>{title}</div>
                </div>
            )}
            width={size === ModalSize.LARGE ? '69%' : '30%'}
        >
            <div className={`${baseClassName}-content`}>{children}</div>
        </AntdModal>
    );
}

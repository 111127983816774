import './ErrorView.css';
import Icon from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdminHeader from '../../Common/adminHeader/adminHeader';

/** The laptop error SVG */
function LaptopErrorSVG() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="121" height="121" viewBox="0 0 121 121" fill="none">
            <path
                d="M22.1009 22.2537C20.0927 22.2537 18.4647 23.8816 18.4647 25.8898V76.3786L10.6575 104.432C10.0126 106.75 11.7552 109.043 14.1606 109.043H117.07C119.476 109.043 121.218 106.75 120.573 104.432L112.766 76.3786V25.8898C112.766 23.8816 111.138 22.2537 109.13 22.2537H22.1009Z"
                fill="#FFD2D5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M99.3685 16.7595L12.2597 16.7595V67.0637H99.3685V16.7595ZM12.2597 13.1633C10.2735 13.1633 8.66345 14.7734 8.66345 16.7595V67.2884L0.842196 95.3926C0.204377 97.6845 1.92778 99.953 4.30675 99.953H107.321C109.7 99.953 111.424 97.6845 110.786 95.3926L102.965 67.2882H102.965V16.7595C102.965 14.7734 101.355 13.1633 99.3685 13.1633H12.2597ZM92.7363 23.2942L18.8917 23.2942V60.3105L92.7363 60.3105L92.7363 23.2942ZM18.8917 19.698C16.9056 19.698 15.2955 21.308 15.2955 23.2942V60.3105C15.2955 62.2966 16.9056 63.9067 18.8917 63.9067H92.7363C94.7225 63.9067 96.3326 62.2966 96.3326 60.3105V23.2942C96.3326 21.308 94.7225 19.698 92.7363 19.698H18.8917ZM107.321 96.3568H4.30675L11.3956 70.8844H100.233L107.321 96.3568ZM14.2086 78.3605C14.2086 77.3675 15.0137 76.5624 16.0068 76.5624H97.0438C98.0368 76.5624 98.8419 77.3675 98.8419 78.3605C98.8419 79.3536 98.0368 80.1586 97.0438 80.1586H16.0068C15.0137 80.1586 14.2086 79.3536 14.2086 78.3605ZM27.4559 89.2692C27.4559 88.2761 28.2609 87.4711 29.254 87.4711H83.7966C84.7897 87.4711 85.5947 88.2761 85.5947 89.2692C85.5947 90.2622 84.7897 91.0673 83.7966 91.0673H29.254C28.2609 91.0673 27.4559 90.2622 27.4559 89.2692ZM63.833 35.2284C64.3716 34.6899 64.3716 33.8168 63.833 33.2783C63.2945 32.7397 62.4214 32.7397 61.8829 33.2783L55.2617 39.8995L48.6407 33.2785C48.1021 32.74 47.229 32.74 46.6905 33.2785C46.152 33.817 46.152 34.6901 46.6905 35.2287L53.3115 41.8497L46.6903 48.4709C46.1518 49.0094 46.1518 49.8825 46.6903 50.421C47.2288 50.9595 48.1019 50.9595 48.6404 50.421L55.2617 43.7998L61.8831 50.4212C62.4216 50.9598 63.2947 50.9598 63.8332 50.4212C64.3718 49.8827 64.3718 49.0096 63.8332 48.4711L57.2118 41.8497L63.833 35.2284Z"
                fill="#EA202C"
            />
        </svg>
    );
}

export default function ErrorView() {
    const { t } = useTranslation('common');
    return (
        <div className="container">
            <AdminHeader displayLogo={false} className="error-header" />
            <div className="error-display">
                <Icon component={LaptopErrorSVG} />
                <h1>{t('error.messageRetry')}</h1>
                <Button
                    id="errorRefreshButton"
                    icon={<FontAwesomeIcon icon="rotate-right" className="error-refresh-icon" />}
                    className="error-refresh-button"
                    onClick={() => window.location.reload()}
                >
                    {t('error.retry')}
                </Button>
            </div>
        </div>
    );
}
